
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import CountiesList from "~/components/molecules/display/CountiesList.vue";
import PlaceHolderCircles from "~/components/molecules/display/PlaceHolderCircles.vue";
import { translateStateName } from "~/mixins/translations";
import { BaseLocation, RouteParams } from "~/types/Location";

@Component({
  components: {
    RouterLink,
    PlaceHolderCircles,
    CountiesList,
  },
})
export default class DistrictOtherLocations extends Vue {
  @Prop({ default: false }) isPelletsSite!: boolean;
  @Prop({ default: false }) isDistrictCity!: boolean;
  @Prop({ required: false, default: false }) isStatePage!: boolean;
  @Prop({ required: true })
  readonly stateDistrictCities!: BaseLocation[];
  @Prop({ required: true })
  readonly otherCountiesInBundesland!: BaseLocation[];
  @Prop({ required: true }) routeParams!: RouteParams;
  @Prop({ required: true })
  readonly moreThanTwentyCounties!: boolean;

  translateStateName = translateStateName;

  getCityKey(city: BaseLocation, index: number): string {
    return `${index}-${city.name}`;
  }

  getCityLink(city: BaseLocation): string {
    return `/${
      this.isPelletsSite ? "pelletspreise" : "heizoelpreise"
    }/bundeslaender/${this.routeParams.category.toLowerCase()}/stadt-${
      city.link
    }`;
  }

  getCityTitle(city: BaseLocation): string {
    if (this.isStatePage || this.isDistrictCity) {
      return `Weiter zur kreisfreien Stadt ${city.name}`;
    }
    return `Weiter zur Ansicht zu ${city.name}`;
  }

  getCityDisplayName(city: BaseLocation): string {
    return city.name;
  }

  getCountyKey(county: BaseLocation, index: number): string {
    return `${index}-${county.name}`;
  }

  getCountyLink(county: BaseLocation): string {
    return `/${
      this.isPelletsSite ? "pelletspreise" : "heizoelpreise"
    }/bundeslaender/${this.routeParams.category.toLowerCase()}/${county.link.toLowerCase()}`;
  }

  getCountyTitle(county: BaseLocation): string {
    return `Weiter zum Landkreis ${county.name}`;
  }

  getCountyDisplayName(county: BaseLocation): string {
    return county.name;
  }

  get getTitleText(): string {
    if (!this.isStatePage && this.isPelletsSite) {
      return `Andere Landkreise${
        this.isPelletsSite ? " mit Pelletspreisen" : ""
      }`;
    }

    const baseText = this.isStatePage
      ? "Kreisfreie Städte"
      : this.isDistrictCity
      ? "Heizölpreise in anderen kreisfreien Städten"
      : "Heizölpreise in anderen Landkreisen";

    const postfix = this.isPelletsSite ? " mit Pelletspreisen" : "";

    if (this.isStatePage && !this.isPelletsSite) {
      return `Heizölpreise der kreisfreien Städte${postfix}`;
    }

    return `${baseText}${postfix}`;
  }

  get getDescriptionText(): string {
    const stateName = translateStateName(this.routeParams.category);
    const priceType = this.isPelletsSite ? "Pelletspreis" : "Heizölpreis";

    if (this.isDistrictCity) {
      return `Für nähere Informationen zum ${priceType} in weiteren kreisfreien Städten in ${stateName}, wählen Sie eine Stadt aus der Liste.`;
    }
    if (this.isStatePage) {
      return `Für nähere Informationen zum ${priceType} in den kreisfreien Städten in ${stateName}, wählen Sie eine Stadt aus der Liste.`;
    }
    return `Für nähere Informationen zum ${priceType} in weiteren Landkreises in ${stateName}, wählen Sie einen Landkreis aus der Liste.`;
  }

  get hasLocations(): boolean {
    return this.isDistrictCity || this.isStatePage
      ? this.stateDistrictCities.length > 0
      : this.otherCountiesInBundesland.length > 0;
  }
}
