
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import PlaceHolderCircles from "~/components/molecules/display/PlaceHolderCircles.vue";
import { Location } from "~/types/Location";

@Component({
  components: {
    RouterLink,
    PlaceHolderCircles,
  },
})
export default class CountiesList extends Vue {
  @Prop({ required: true }) items!: Location[];
  @Prop({ type: Function, required: true }) getItemKey!: (
    item: any,
    index: number,
  ) => string;
  @Prop({ type: Function, required: true }) getItemLink!: (item: any) => string;
  @Prop({ type: Function, required: true }) getItemTitle!: (
    item: any,
  ) => string;
  @Prop({ type: Function, required: true }) getItemDisplayName!: (
    item: any,
  ) => string;
  @Prop({ default: false }) showPlaceholders!: boolean;
}
